<template>
  <b-container id="page" fluid>
    <b-navbar toggleable="lg" variant="faded" type="light">
      <b-navbar-brand tag="h1"  class="mb-0">Voter WebUI</b-navbar-brand>
      
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/">Return to Dashboard</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-modal id="bp-modal" title="Build Patch" no-close-on-backdrop no-close-on-esc hide-header-close centered>   

      <b-alert variant="warning" :show="!runningBuildPatchStatus">Build Patches can be used by dashboard developers to test new features without affecting everyone. Make sure to only use patches from trusted sources, as dangerous patch could compromise your information.</b-alert>

      <b-card>
        <b-card-text style="font-size: 17pt"><b>ID:</b> {{requestedBuildPatchId || runningBuildPatch || ""}}</b-card-text>
        <b-card-text style="font-size: 17pt"><b>Status:</b> <b-icon :icon="validBuildPatchCheck?'check-circle-fill':'x-circle-fill'" :variant="validBuildPatchCheck?'success':'danger'"/> {{validBuildPatchCheck?'Valid':'Invalid'}}</b-card-text>
        <b-card-text v-show="!runningPatchDifferentName" style="font-size: 17pt"><b>Applied:</b> <b-icon :icon="runningBuildPatchStatus?'check-circle-fill':'x-circle-fill'" :variant="runningBuildPatchStatus?'success':'danger'"/> {{runningBuildPatchStatus?'Applied':'Not Applied'}}</b-card-text>
        <b-card-text v-show="runningPatchDifferentName" style="font-size: 17pt"><b>Applied:</b> <b-icon-exclamation-circle-fill variant="warning"/> Another patch applied ({{runningBuildPatch}})</b-card-text>
      </b-card>
      <div slot="modal-footer">
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button :disabled="currentlyProcessing" variant="outline-secondary" to="/">Cancel</b-button>
          </b-col>
          <b-col cols="auto">
            <b-button @click="removeBuildPatch" :disabled="enableRemovePatchButton" variant="outline-danger" v-b-tooltip.hover title="Removing this patch will reload the page and return to normal build">Remove {{runningBuildPatchStatus?'Current ':''}}Patch</b-button>
          </b-col>
          <b-col cols="auto">
            <b-button @click="applyBuildPatch" :disabled="enableApplyPatchButton" variant="outline-success" v-b-tooltip.hover>Apply Patch</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal hide-header hide-footer centered no-close-on-backdrop no-close-on-esc id="processing" v-model="currentlyProcessing">
      <h1 class="text-center">Processing...</h1>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  name: 'BuildPatch',
  data(){
    return {
      processing:false,
      invalidBuild:false
    }
  },
  methods:{
    reloadPage(){
      window.location.reload()
    },
    async checkBuildPatch(){
      let res = await fetch(`/_patches/${this.requestedBuildPatchId || this.runningBuildPatch}`, {"method":"GET"})
      if(res.status == 200){
        this.invalidBuild = false
      }else{
        this.invalidBuild = true
      }
    },
    applyBuildPatch(){
      this.processing = true;
      this.$nextTick().then(() => {
        fetch(`/_patches/${this.requestedBuildPatchId}`, {"method":"POST"})
        .then(r => {
          if(r.status == 200){
            this.reloadPage()
          }else{
            this.processing = false;
          }
        })
      })
    },
    removeBuildPatch(){
      this.processing = true;
      this.$nextTick().then(() => {
        this.$cookies.remove("build_patch")
        this.removeBuildPatchFailsafe();
      })
    },
    removeBuildPatchFailsafe(){
      this.processing = true;
      this.$nextTick().then(() => {
        this.$cookies.remove("build_patch")
        window.location.reload()
      })
    }
  },
  computed:{
    validBuildPatchCheck(){
      return !this.invalidBuild
    },
    runningBuildPatchStatus(){
      return this.$cookies.get("build_patch") && this.$cookies.get("build_patch") != ''
    },
    runningBuildPatch(){
      return this.$cookies.get("build_patch") || false
    },
    requestedBuildPatchId(){
      return this.$route.query.id
    },
    currentlyProcessing(){
      return this.processing
    },
    attemptingToAddNewPatchWhileRunningPatch(){
      return this.$route.query.id && this.runningBuildPatchStatus
    },
    runningPatchDifferentName(){
      if(!this.runningBuildPatchStatus)
        return false
      return this.$route.query.id && this.$route.query.id != this.runningBuildPatch
    },
    enableRemovePatchButton(){
      return !this.runningBuildPatchStatus || this.currentlyProcessing
    },
    enableApplyPatchButton(){
      return this.runningBuildPatchStatus || this.currentlyProcessing || this.validBuildPatchCheck == false
    }
  },
  created(){
    if(this.$route.query.removeFailsafe)
      this.removeBuildPatchFailsafe()
  },
  mounted(){
    this.checkBuildPatch()
    this.$bvModal.show("bp-modal")
  }
}

</script>